<template>
    <v-card flat class="mt-3">
        <v-card-title class="pl-7 block-title" style="border-style: solid solid none solid; border-width: thin; border-color: rgba(0, 0, 0, 0.12)">
            <v-row>
                <v-col ref="colHeader">
                    <template v-for="(breadCrumbItem, breadCrumbIdx) in breadCrumbs">
                        <template v-if="availDrawMenuHeader(breadCrumbIdx)">
                            <v-icon :key="'bread_crumb_delim_'+breadCrumbIdx" v-if="breadCrumbIdx > 0" small class="ml-2 mr-2">mdi-greater-than</v-icon>
                            <span :key="'bread_crumb'+breadCrumbIdx"> {{ textTruncate(breadCrumbIdx, breadCrumbItem) }} </span>
                        </template>
                    </template>
                </v-col>
                <v-col  class="text-right" style="margin-top: -6px;">
                    <v-btn outlined large rounded class="mr-2" color="primary" @click="deleteMenu" style="font-size:16px;">
                        <v-icon left dark size="20">mdi-minus-circle</v-icon>
                        메뉴 삭제
                    </v-btn>
                    <v-btn outlined large rounded color="primary" @click="modifyMenu" style="font-size:16px;">
                        <v-icon left dark size="20">mdi-check</v-icon>
                        저장
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="pl-0 pr-0 pb-0">
            <v-simple-table class="border-table">
                <tbody>
                    <tr class="no-hover">
                        <td class="pt-5 pl-7 pr-3 pb-5" style="width:150px; vertical-align: top; font: normal normal 600 18px/25px Apple SD Gothic Neo !important;">메뉴명</td>
                        <td class="pt-5 pl-7 pr-7 pb-5">
                            <v-text-field color="#333333" outlined class="ma-0" hide-details v-model="selectedMenu.menu_name"></v-text-field>
                        </td>
                    </tr>
                    <tr v-if="menuType == 'PTN_LINK'" class="no-hover">
                        <td class="pt-5 pl-7 pr-3 pb-5" style="width:150px; vertical-align: top; font: normal normal 600 18px/25px Apple SD Gothic Neo !important;">파트너 링크URL</td>
                        <td class="pt-5 pl-7 pr-7 pb-5">
                            <v-text-field color="#333333" outlined class="ma-0" hide-details v-model="selectedMenu.link_url"></v-text-field>
                        </td>
                    </tr>
                    <tr v-if="menuType == 'RING_GROUP' && ringUserList.length > 1" class="no-hover">
                        <td class="pt-5 pl-7 pr-3 pb-5" style="width:150px; vertical-align: top; font: normal normal 600 18px/25px Apple SD Gothic Neo !important;">통화 순서</td>
                        <td class="pt-5 pl-7 pr-7 pb-5">
                            <v-radio-group class="mt-0" hide-details v-model="selectedMenu.ring_order_type">
                                <v-radio value="SEQ">
                                    <template v-slot:label>
                                        <span style="font-size:18px;">순서대로 연결 <span class="grey--text">(등록된 순서에 따라 통화 요청)</span></span>
                                    </template>
                                </v-radio>
                                <v-radio value="RANDOM">
                                    <template v-slot:label>
                                        <span style="font-size:18px;">무작위로 연결 <span class="grey--text">(등록된 상담원 중 무작위로 통화 요청)</span></span>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </td>
                    </tr>
                    <tr v-if="menuType == 'RING_GROUP'" class="no-hover">
                        <td class="pt-5 pl-7 pr-5 pb-5" style="width:150px; vertical-align: top; font: normal normal 600 18px/25px Apple SD Gothic Neo !important;">연결 상담원</td>
                        <td class="pt-5 pl-7 pr-7 pb-5">
                            <v-card tile outlined>
                                <v-card-text>
                                    <v-simple-table class="border-table">
                                        <template v-slot:default>
                                            <thead>
                                                <tr style="background-color:#F4F6F8; height:65px; color:#333333; font-weight:600;">
                                                    <th class="text-left pl-5 font-text-mid">
                                                        순번
                                                    </th>
                                                    <th class="text-left pl-3 font-text-mid">
                                                        담당자명
                                                    </th>
                                                    <th class="text-left pl-3 font-text-mid">
                                                        비고
                                                    </th>
                                                    <th class="text-center font-text-mid" style="width:80px;">
                                                        삭제
                                                    </th>
                                                    <th class="text-center font-text-mid" style="width:80px;">
                                                        순서
                                                    </th>
                                                </tr>
                                            </thead>
                                            <draggable v-model="selectedMenu.ring_user_list" tag="tbody" @start="onDragStart" @end="onDragEnd" handle=".handle" style="font-size:18px; color:#333333;">
                                                <tr style="height:68px;" :key="ringUserIdx" v-for="(ringUserItem, ringUserIdx) in selectedMenu.ring_user_list"
                                                    :class="{ 'handle': true, 'selectedTr': (ringUserIdx == selectedIdx) }">
                                                    <td class="pl-5 font-text-mid">{{ ringUserItem.display_order }}</td>
                                                    <td class="pl-3 font-text-mid">{{ ringUserItem.user_name }}</td>
                                                    <td class="pl-3 font-text-mid">{{ ringUserItem.mng_memo }}</td>
                                                    <td class="font-text-mid text-center">
                                                        <v-btn icon v-if="selectedMenu.ring_user_list.length > 1" @click="deleteRingUser(ringUserItem)">
                                                            <v-icon>mdi-delete-outline</v-icon>
                                                        </v-btn>
                                                    </td>
                                                    <td class="font-text-mid text-center">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn icon v-on="on">
                                                                    <v-icon>mdi-drag-horizontal-variant</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>드래그하여 순서를 변경할 수 있습니다</span>
                                                        </v-tooltip>
                                                    </td>
                                                </tr>
                                            </draggable>
                                        </template>
                                    </v-simple-table>
                                </v-card-text>
                                <v-card-text class="text-center" style="background-color:#F4F6F8; border-top:1px solid #eeeeee; height:65px;">
                                    <v-btn icon @click="openRingUserAddPopup">
                                        <v-icon>mdi-plus-circle</v-icon>
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
        <v-dialog v-model="modal" persistent max-width="600" @keydown.esc="closePopup()">
            <v-flex v-if="dialogMode === 'add'">
                <popup-ring-user-add v-on:submit="submitRingUserAdd" v-on:cancel="closePopup"></popup-ring-user-add>
            </v-flex>
        </v-dialog>
    </v-card>
</template>
<script>
import {mapGetters, mapState} from 'vuex'
import svcVoiceScnApi from '@/api/svc_voice_scn'
import draggable from 'vuedraggable'

export default {
    name: 'svc_voice_scn_detail',
    components: {
        'popupRingUserAdd': () => import('@/components/svc/voice_scn/popup_ring_user_add.vue'),
        draggable
    },
    data: () => ({
        modal: false,
        dialogMode: 'none',
        selectedIdx: -1,
        drawMenuConfig: {
            headerWidth: 0,
            availCount: 0,
            dispAvailByte: 0,
            availDispBytes: []
        }
    }),
    created() {
        window.addEventListener('resize', this.setMenuAreaConfig)
    },
    mounted() {
        window.addEventListener('resize', this.setMenuAreaConfig)
        this.setMenuAreaConfig()
    },
    beforeUpdate() {
        this.setMenuAreaConfig()
    },
    computed: {
        ...mapState('svcVoiceScn', {
            selectedMenu: state => state.selectedMenu,
        }),
        ...mapGetters({
            allMenuList: 'svcVoiceScn/getMenuList',
            curMenu: 'svcVoiceScn/getSelectedMenu',
        }),
        isLoaded() {
            if (this.selectedMenu) {
                return true
            }
            return false
        },
        selectedMenuSeqno() {
            return (this.selectedMenu ? this.selectedMenu.menu_seqno : 0)
        },
        menuType() {
            return (this.selectedMenu ? this.selectedMenu.menu_type : '')
        },
        ringUserList() {
            return (this.selectedMenu && this.selectedMenu.ring_user_list ? this.selectedMenu.ring_user_list : [])
        },
        breadCrumbs() {
            let ret = []

            if (this.curMenu) {
                let menuHist = [this.selectedMenu]

                for (let i = 0; i < this.selectedMenu.menu_depth; i++) {
                    let lastMenu = menuHist[menuHist.length - 1]
                    let parentMenu = null
                    if (lastMenu.parent_menu_seqno > 0) {
                        this.allMenuList.forEach(menuItem => {
                            if (menuItem.menu_seqno == lastMenu.parent_menu_seqno) {
                                parentMenu = menuItem
                            }
                        })
                    }
                    if (parentMenu) {
                        menuHist.push(parentMenu)
                    } else {
                        break
                    }
                }

                for (let i = menuHist.length - 1; i >= 0; i--) {
                    ret.push(menuHist[i].menu_name)
                }
            }

            return ret
        }
    },
    methods: {
        reloadMenuList(scb) {
            this.$store.dispatch('svcVoiceScn/getMenuList', {
                scb: () => {
                    if (scb) {
                        scb()
                    }
                }
            })
        },
        deleteRingUser(ringUser) {
            let userSeqno = ringUser.user_seqno

            this.$store.dispatch('openConfirm', {
                message: '상담원을 목록에서 삭제하시겠습니까?',
                sub_message: '변경된 내용은 저장을 클릭해야 실제 반영됩니다',
                va: this,
                okCb: function (va) {
                    va.$store.commit('svcVoiceScn/deleteRingUser', {
                        user_seqno: userSeqno,
                    })
                    va.$store.commit('svcVoiceScn/resetRingUserOrder', {})
                }
            })
        },
        deleteMenu() {
            let parentMenuSeqno = (this.selectedMenu ? this.selectedMenu.parent_menu_seqno : 0)
            let menuSeqno = this.selectedMenuSeqno

            this.$store.dispatch('openConfirm', {
                message: '현재 메뉴를 삭제하시겠습니까?',
                va: this,
                okCb: function (va) {
                    svcVoiceScnApi.deleteMenu({
                        menu_seqno: menuSeqno,
                    }, () => {
                        va.reloadMenuList(() => {
                            va.$store.commit('svcVoiceScn/selectMenu', {menu_seqno: parentMenuSeqno})
                        })
                    }, (err) => {
                        va.$store.dispatch('openAlert', {
                            message: '메뉴 삭제를 실패 했습니다',
                            sub_message: err
                        })
                    }, va)
                }
            })
        },
        openRingUserAddPopup() {
            this.dialogMode = 'add'
            this.modal = true
        },
        closePopup() {
            this.modal = false
            this.dialogMode = 'none'
        },
        submitRingUserAdd() {
            this.$store.commit('svcVoiceScn/resetRingUserOrder', {})
            this.closePopup()
        },
        modifyMenu() {
            this.$store.dispatch('openConfirm', {
                message: '현재 메뉴 정보를 저장하시겠습니까?',
                va: this,
                okCb: function (va) {
                    va.$store.dispatch('svcVoiceScn/modifyMenu', {
                        scb: (va) => {
                            va.reloadMenuList()
                        }, fcb: (err, va) => {
                            va.$store.dispatch('openAlert', {
                                message: '메뉴 정보 저장을 실패 했습니다',
                                sub_message: err
                            })
                        }, va: va
                    })
                }
            })
        },
        onDragStart(p) {
            this.selectedIdx = p.oldIndex
        },
        onDragEnd(p) {
            this.$store.commit('svcVoiceScn/resetRingUserOrder', {})

            let ctx = this
            setTimeout(() => {
                ctx.selectedIdx = -1
            }, 1000)
        },
        getByteLength(s) {

            if (s == null || s.length == 0) {
                return 0;
            }
            let size = 0;

            for ( let i = 0; i < s.length; i++) {
                size += this.charByteSize(s.charAt(i));
            }

            return size;
        },
        cutByteLength(s, len) {

            if (s == null || s.length == 0) {
                return 0;
            }
            let size = 0;
            let rIndex = s.length;

            for ( let i = 0; i < s.length; i++) {
                size += this.fixedCharByteSize(s.charAt(i));
                if( size == len ) {
                    rIndex = i + 1;
                    break;
                } else if( size > len ) {
                    rIndex = i;
                    break;
                }
            }

            return s.substring(0, rIndex);
        },
        charByteSize(ch) {
            if (ch == null || ch.length == 0) {
                return 0;
            }

            let charCode = ch.charCodeAt(0);

            if (charCode <= 0x00007F) {
                return 1;
            } else if (charCode <= 0x0007FF) {
                return 2;
            } else if (charCode <= 0x00FFFF) {
                return 3;
            } else {
                return 4;
            }
        },
        fixedCharByteSize(ch) {
            if (ch == null || ch.length == 0) {
                return 0;
            }

            let charCode = ch.charCodeAt(0);

            if (charCode <= 0x00007F) {
                return 2;
            } else if (charCode <= 0x0007FF) {
                return 2;
            } else if (charCode <= 0x00FFFF) {
                return 3;
            } else {
                return 4;
            }
        },
        textTruncate(idx, v) {
            if (!v || v.trim().length == 0) {
                return v

            } else if (this.drawMenuConfig.headerWidth < 150) {
                return this.getByteLength(v) < 8 ? v : `${this.cutByteLength(v, 8)}..`
            }

            let appendEllipsisFlag = this.getByteLength(v) > this.drawMenuConfig.availDispBytes[idx] ? true : false
            if (appendEllipsisFlag) {
                return `${this.cutByteLength(v, this.drawMenuConfig.availDispBytes[idx] - 10)}..`
            } else {
                return `${this.cutByteLength(v, this.drawMenuConfig.availDispBytes[idx])}`
            }
        },
        setMenuAreaConfig() {
            let ctx = this

            // 메뉴헤더 영역 width 값 계산
            if (this.$refs.colHeader) {
                this.drawMenuConfig.headerWidth = this.$refs.colHeader.clientWidth - 30
            }

            // 메뉴헤더 영역 width 값에 따른 메뉴Depth 노출 가능 갯수 계산
            if (this.drawMenuConfig.headerWidth < 150) {
                this.drawMenuConfig.availCount = 1
            } else if (this.drawMenuConfig.headerWidth > 150 && this.drawMenuConfig.headerWidth < 250) {
                this.drawMenuConfig.availCount = 2
            } else if (this.drawMenuConfig.headerWidth > 250 && this.drawMenuConfig.headerWidth < 450) {
                this.drawMenuConfig.availCount = 3
            } else {
                this.drawMenuConfig.availCount = 4
            }

            // 메뉴명 truncate 시작 인덱스
            // width: 583 = 35 자, width: 743 = 45 자 (한글기준 36자 (.. 포함. 한글1글자당 3 byte))
            // 583 / 35 = 16.6..., 743 / 45 = 16.1xxx

            let dispAvailByte = parseInt(this.drawMenuConfig.headerWidth / 16) * 3

            // span 별 실제 노출 가능 글자
            this.drawMenuConfig.dispAvailByte = dispAvailByte / (this.breadCrumbs.length < this.drawMenuConfig.availCount ? this.breadCrumbs.length : this.drawMenuConfig.availCount)

            let remainLength = 0

            // 메뉴 길이가 노출할 수 있는 길이보다 짧을 경우 남은 여분을 계산하여 최대 노출 가능 길이를 설정한다. (길이가 길어 짤리는 메뉴명을 최대한 더 보여주기 위해..)
            this.breadCrumbs.forEach((item, idx) => {
                // 노출 할 수 있는 갯수를 초과 할 경우 중지
                if (idx >= ctx.drawMenuConfig.availCount) {
                    return
                }

                let currentItemByte = ctx.getByteLength(item)

                // 노출 가능한 길이보다 짧을 경우 여분 길이로 보관
                if (currentItemByte < ctx.drawMenuConfig.dispAvailByte) {
                    remainLength = remainLength + ctx.drawMenuConfig.dispAvailByte - currentItemByte
                }

            })

            this.breadCrumbs.forEach((item, idx) => {
                let dispLength = ctx.drawMenuConfig.dispAvailByte
                let currentItemByte = ctx.getByteLength(item)

                // 메뉴명 길이가 노출 할 수 있는 길이보다 길다면 여분 길이 추가
                if (remainLength > 0 && currentItemByte > ctx.drawMenuConfig.dispAvailByte) {

                    // 노출 가능한 길이 계산
                    dispLength = ctx.drawMenuConfig.dispAvailByte + remainLength

                    // 노출 할 수 있는 길이 + 여분길이가 메뉴명의 길이보다 길다면 남은 여분의 길이는 다시 보관
                    if (ctx.drawMenuConfig.dispAvailByte + remainLength > currentItemByte) {

                        // 남은 여분 계산하여 보관
                        remainLength = ctx.drawMenuConfig.dispAvailByte + remainLength - currentItemByte
                    } else {
                        remainLength = 0
                    }
                }

                // 해당 인덱스에 노출 가능한 값 셋팅
                ctx.drawMenuConfig.availDispBytes[idx] = dispLength
            })
        },
        availDrawMenuHeader(idx) {
            return (idx + 1) <= this.drawMenuConfig.availCount
        }
    }
}</script>